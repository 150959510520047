import React from "react";
import { connect } from "react-redux";
import withStyles from "isomorphic-style-loader/lib/withStyles";

import s from "./AppLayout.css";
import { Header, Link } from "../../components";
import { Layout, Menu, Icon } from "antd";
import antStyles from "antd/dist/antd.less";
import globalStyles from "../../global.css";
const { SubMenu } = Menu;
const { Sider } = Layout;
import { HOME_ROUTE } from "../../routes";
import history from "../../history";

import { clearForms, updateNewRecipeName } from "../../reducers/product";

import { SIDER_WIDTH } from "../../constants";

class AppLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      showSider: false,
      mounted: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    this.updateWindowDimensions();
    this.setState({ mounted: true });
  }

  updateWindowDimensions = (event) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  getLogo = (sider_width) => {
    const logoimg = <img className={s.logoimg} src={require("../../static/bplogo.svg")} alt={"logo"} />;

    if (sider_width == 0) {
      return <span onClick={this.toggle}>{logoimg}</span>;
    } else if (this.props.currentRouteName === HOME_ROUTE) {
      return logoimg;
    } else {
      return <Link to={HOME_ROUTE}>{logoimg}</Link>;
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  handleClickOutside = async (event) => {
    if (this.node.contains(event.target)) {
      return;
    }

    await this.setState({ collapsed: true });
  };

  static defaultProps = {
    footer: true,
  };

  siderLeave = () => {
    this.setState({
      collapsed: true,
    });
  };

  siderEnter = () => {
    this.setState({
      collapsed: false,
    });
  };

  render() {
    const { footer, currencies, currentRouteName } = this.props;

    const user = this.props.user;
    const sider_width = this.state.windowWidth && this.state.windowWidth > 880 ? SIDER_WIDTH : 0;
    // mobileForNav does not exist
    const sider_class = sider_width === 0 ? s.mobileForNav : s.forNav;

    const handleLogout = () => {
      var widget = document.getElementById("searchie-fixed-btn");
      if (widget) widget.parentNode.removeChild(widget);
      var script = document.getElementById("widget_script-fixed-btn");
      if (script) script.parentNode.removeChild(script);
    };

    return (
      <Layout className={s.container}>
        {this.state.mounted && currencies && (
          <React.Fragment>
            <div>
              {this.props.user != null && (
                <React.Fragment>
                  <div className={s.leftmenu} ref={(node) => (this.node = node)}>
                    <div className={s.topleftnav}>
                      <div className={s.logo}>{this.getLogo(sider_width)}</div>
                    </div>
                    {this.props.user != null && (
                      <div className={sider_class}>
                        <Sider
                          className={s.sider}
                          collapsible
                          collapsedWidth={sider_width}
                          collapsed={this.state.collapsed}
                          onMouseLeave={this.siderLeave}
                          onMouseEnter={this.siderEnter}
                          trigger={null}
                        >
                          <Menu mode="inline" className={s.menuMobileTop}>
                            <Menu.Item className={s.ant_menu_horizontal} key="home" onClick={() => history.push("/")}>
                              <Icon type="home" />
                              <span> Home </span>
                            </Menu.Item>
                            <Menu.Item
                              className={s.ant_menu_horizontal}
                              key="create"
                              onClick={() => {
                                {
                                  /* this.props.clearForms(); */
                                }
                                history.push("/product");
                                {
                                  /* this.props.updateNewRecipeName('Untitled') */
                                }
                              }}
                            >
                              <Icon type="plus-circle" />

                              <span>Create!</span>
                            </Menu.Item>
                            <Menu.Item className={s.ant_menu_horizontal} key="listproducts" onClick={() => history.push("/listproducts")}>
                              <Icon type="menu" />
                              <span> My Formulations </span>
                            </Menu.Item>
                            <Menu.Item className={s.ant_menu_horizontal} key="resources" onClick={() => history.push("/resources")}>
                              <Icon type="question-circle" />
                              <span>Resources</span>
                            </Menu.Item>
                            <SubMenu
                              className={s.ant_menu_horizontal}
                              title={
                                <span className="submenu-title-wrapper">
                                  <Icon type="team" />
                                  <span>Community</span>
                                </span>
                              }
                            >
                              <Menu.Item
                                key="setting:1"
                                onClick={() =>
                                  window.open("https://www.facebook.com/groups/2081197135340829/?source_id=109407770469194", "_blank")
                                }
                              >
                                <span> FB Group AIB </span>
                              </Menu.Item>
                              <Menu.Item
                                key="setting:2"
                                onClick={() => window.open("https://www.facebook.com/groups/1016692035359049/", "_blank")}
                              >
                                <span>FB Group BP</span>
                              </Menu.Item>
                            </SubMenu>

                            <SubMenu
                              className={s.ant_menu_horizontal}
                              title={
                                <span className="submenu-title-wrapper">
                                  <Icon type="book" />
                                  <span>Help</span>
                                </span>
                              }
                            >
                              <Menu.Item
                                key="setting:1"
                                onClick={() => window.open("https://academy.blendprecisely.io/products/blend-precisely", "_blank")}
                              >
                                <span> Starter Tutorials </span>
                              </Menu.Item>

                              <Menu.Item
                                key="setting:2"
                                onClick={() => window.open("https://app.searchie.io/widget/Pm1Z07aLl2#/", "_blank")}
                              >
                                <span>All Tutorials</span>
                              </Menu.Item>
                            </SubMenu>

                            <Menu.Item key="about" className={s.ant_menu_horizontal} onClick={() => history.push("/about")}>
                              <Icon type="info-circle" />
                              <span>About</span>
                            </Menu.Item>
                          </Menu>
                        </Sider>
                        <Header toggleSider={this.toggle} />
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
            <Layout.Content className={s.content}>{this.props.children}</Layout.Content>
          </React.Fragment>
        )}
      </Layout>
    );
  }
}

const mapState = (state) => ({
  user: state.user.user,
  currencies: state.global.currencies,
  currentRouteName: state.global.currentRouteName,
});

const mapDispatch = {
  clearForms,
  updateNewRecipeName,
};

export default connect(mapState, mapDispatch)(withStyles(antStyles, globalStyles, s)(AppLayout));
